<script setup>
/* global tizen */
  // import mixpanel from "mixpanel-browser";
  import { onMounted, onUnmounted } from 'vue';
  import ActivationCode from "./views/ActivationCode.vue";

  const handleKeyDown = (event) => {
    if (!event || typeof event.keyCode === 'undefined') {
      console.error('Invalid keydown event received:', event);
      return;
    }
    
    const keyMap = {
      37: 'LEFT',
      38: 'UP',
      39: 'RIGHT',
      40: 'DOWN',
      13: 'ENTER',
      10009: 'RETURN',
    };

    const keyName = keyMap[event.keyCode] || `UNKNOWN`;
    console.log(`Key pressed: ${keyName} (code: ${event.keyCode})`);
      
    if (keyName !== 'UNKNOWN') {
      // Pass the key event to the child component
      const activationComponent = document.querySelector('.activation-component');
      if (activationComponent) {
        // Create and dispatch a custom event
        const customEvent = new CustomEvent('tvremote', {
          detail: { 
            keyName: keyName, 
            keyCode: event.keyCode 
          },
          bubbles: true  // Allow event to bubble up the DOM
        });
        activationComponent.dispatchEvent(customEvent);
      } else {
        console.warn('No activation component found to dispatch event to');
      }
    } else {
      console.warn(`Unrecognized key code: ${event.keyCode}`);
    }
  };

  onMounted(() => {
    if (typeof tizen !== "undefined" && tizen.tvinputdevice) {
      console.log("Registering Tizen remote keys...");
      try {
        tizen.tvinputdevice.registerKey("LEFT");
        tizen.tvinputdevice.registerKey("RIGHT");
        tizen.tvinputdevice.registerKey("UP");
        tizen.tvinputdevice.registerKey("DOWN");
        tizen.tvinputdevice.registerKey("ENTER");
        tizen.tvinputdevice.registerKey("RETURN");
        console.log("Remote keys registered successfully");
      } catch (error) {
        console.error("Error registering keys:", error);
      }
    }
    
    document.addEventListener("keydown", handleKeyDown);
  });

  onUnmounted(() => {
    document.removeEventListener("keydown", handleKeyDown);
  });

  // onMounted(() => {
  //   mixpanel.init("df1ff14c11481629aa323409e67174a9",{
  //     ignore_dnt: true,
  //     track_pageview: true,
  //     persistence: "localStorage",
  //   })
  // });
</script>
<template>
  <div class="main">
    <ActivationCode class="activation-component"/>
  </div>
</template>
<style>
.main {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
