const birthday = 'img/birthday.png';
const welcome = 'img/welcome.png';
const anniversay = 'img/anniversary.png';
const theme4 = 'img/theme4.png';

const themes = [
    {
        color: 'Theme 1',
        themes: birthday,
        fontColor1: '#ffffff',
        fontColor2: '#ffffff',
        fontColor3: '#ffffff',
        Background: 'transparent',
    },
    {
        color: 'Theme 2',
        themes: welcome,
        fontColor1: '#ffffff',
        fontColor2: '#ffffff',
        fontColor3: '#ffffff',
        Background: 'transparent',
    },
    {
        color: 'Theme 3',
        themes: anniversay,
        fontColor1: '#ffffff',
        fontColor2: '#ffffff',
        fontColor3: '#ffffff',
        Background: 'transparent',
    },
    {
        color: 'Theme 4',
        themes: theme4,
        fontColor1: '#ffffff',
        fontColor2: '#ffffff',
        fontColor3: '#ffffff',
        Background: 'transparent',
    },
    {
        color: 'image',
        fontColor1: '#ffffff',
        fontColor2: '#ffffff',
        fontColor3: '#ffffff',
        Background: 'transparent',
    },
]

export default themes;