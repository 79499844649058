<script>
import http from "../networkUtils/networkUtil";
import { usePlaylistStore } from "../store/PlaylistStore";


export default {
    name: "PlayerControls",
    props: ['togglePlayerInfo', 'togglePlayerControls', 'nextSlide', 'prevSlide', 'toggleSlide' , 'currentSlide', 'videoDuration', 'isActive'],
    mounted: function(){
        this.getPlaylistName();
    },
    data: function(){
        return{
            progressValue: 0,
            intervalId: null,
            duration: null,
            playlist: '',
            slidesType: []
        }
    },
    watch: {
        currentSlide: {
            immediate: true,
            handler(newSlide) {
                if (newSlide) {
                    this.duration = newSlide.duration; 
                    this.startProgress(this.duration);
                }
            },
        },
    },
    setup: function () {
        var playlistStore = usePlaylistStore();

        return {
            playlistStore: playlistStore,
        };
    },
    methods: {
        startProgress(duration) {
            if (!duration) return;
            this.progressValue = 0;
            this.duration = duration;
            this.clearProgress(); // Clear existing progress before starting a new one
            this.elapsed = 0;

            this.intervalId = setInterval(() => {
                if (!this.isActive) return; // Pause progress when isActive is false

                this.elapsed += 100; 
                this.progressValue = this.elapsed;

                if (this.elapsed >= duration) {
                    this.clearProgress();
                    if(this.currentSlide.type !== 'video'){
                        this.nextSlide();
                    }
                }
            }, 100);
        },
        toggleProgress() {
            if (this.isActive) {
                this.resumeProgress();
            }
        },
        resumeProgress() {
            if (!this.duration || this.elapsed >= this.duration) return;
            this.intervalId = setInterval(() => {
                if (!this.isActive) return;

                this.elapsed += 100; 
                this.progressValue = this.elapsed;

                if (this.elapsed >= this.duration) {
                    this.clearProgress();
                }
            }, 100);
        },
        clearProgress() {
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
        },
        getPlaylistName: function() {
            var playlist = this.playlistStore.playlist.slides; 
            for (var i = 0; i < playlist.length; i++) {
                var slide = playlist[i];
                this.slidesType.push(slide.type);
            }
            if (playlist && playlist.length > 0) {
                var playlistID = playlist[0].playlist_id;
                http.get(`/playlist/getname/${playlistID}`).then((data)=>{
                    this.playlist = data.data;
                })
            } else {
                console.log('Playlist is empty or not valid');
            }
        },
    },
    beforeUnmount() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
}
</script>
<template>
    <div class="cntrls-main">
        <div style="display: flex; flex-direction: column; gap: 12px">
            <div style="gap: 4px; display: flex; justify-content: space-between">
                <div style="display: flex;">
                    <p class="cntrls-scrn-info">{{ playlist }}</p>
                </div>
                <div style="display: flex; gap: 8px;">
                    <img @click.stop="togglePlayerInfo()" style="cursor: pointer;" src="img/info-circle.svg" alt="">
                    <img @click.stop="togglePlayerControls()" style="cursor: pointer;" src="img/x-close.svg" alt="">
                </div>
            </div>
            <hr style="background-color: rgba(0, 0, 0, 0.1); border: none; height: 0.5px;">
            <div v-for="(slides, index) in slidesType" :key="index" class="cntrls-slides">
                <p>{{ index+1 }}. <span style="text-transform: capitalize">{{ slides }}</span> </p>
            </div>
        </div>
        <div style=" width: 100%; display: flex; justify-content: center; align-items: center;">
            <progress class="colored" style="width: 100%; height: 3px; border-radius: 0px;" id="file" :value="progressValue" :max="duration || 100"></progress>
        </div>
        <div class="cntrls-bottom-container">
            <div>
                <img @click.stop="prevSlide()" style="cursor: pointer;" src="img/skip-back.svg" alt="">
            </div>
            <div>
                <img v-if="isActive" @click.stop="toggleSlide()" style="cursor: pointer;" src="img/pause-circle.svg" alt="">
                <img v-else @click.stop="toggleSlide(); toggleProgress();" style="cursor: pointer;" src="img/play-fill.svg" alt="">
            </div>
            <div>
                <img @click.stop="nextSlide();" style="cursor: pointer;" src="img/skip-forward.svg" alt="">
            </div>
        </div>
    </div>
</template>
<style>
 .cntrls-main{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 16px;
    width: 256px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #F5F7FA;
    box-shadow: 0px 78px 47px rgba(0, 0, 0, 0.01), 0px 35px 35px rgba(0, 0, 0, 0.02), 0px 9px 19px rgba(0, 0, 0, 0.03);
    border-radius: 11px;
 }
 .cntrls-scrn-info{
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #0E121B;
 }
 .cntrls-duration{
    font-size: 10px;
    font-family: Inter;
    color: #989898;
 }
 .cntrls-top-container{
    display: flex;
    justify-content: space-between;
    align-items: start;
 }
 .cntrls-slides{
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0E121B;
    text-transform: capitalize;
 }

.colored {
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.2s ease;
}

.colored::-webkit-progress-bar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  height: 3px;
}

.colored::-webkit-progress-value {
  background-color: #000000;
  border-radius: 0px;
  transition: all 0.2s ease;
}

.colored::-moz-progress-bar {
  background-color: #000000;
  border-radius: 0px;
  transition: all 0.2s ease;
}

.colored {
  background-color: rgba(0, 0, 0, 0.1);
}

.cntrls-bottom-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
 }

.cntrls-main img{
    width: 20px;
    height: 20px;
 }

.slide-type{
    font-size: 12px;
    font-family: Inter;
    color: #989898;
 }
</style>