import {
    createApp
} from 'vue';
import App from './App.vue';
import './assets/styles.css'
import pinia from './store';


if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        // navigator.serviceWorker.register(`${process.env.BASE_URL}service-worker.js?v=${new Date().getTime()}`)
        navigator.serviceWorker.register(`service-worker.js`)
            .then(registration => {

                // Listen for updates to the Service Worker.
                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;
                    installingWorker.onstatechange = () => {
                        if (installingWorker.state === 'installed') {
                            if (navigator.serviceWorker.controller) {
                                // New update available
                                console.log('New content is available; please refresh.');
                                // Trigger an update prompt or refresh the page
                                // showUpdatePrompt(installingWorker);
                            } else {
                                // No previous SW, this is the first installation
                                console.log('Content is now available offline!');
                            }
                        }
                    };
                };
            })
            .catch(error => {
                console.error('ServiceWorker registration failed:', error);
            });

    });
}


createApp(App).use(pinia).mount('#app')