const size = [
    {
        size: 'xs',
        title: '2.94vw',
        subtitle: '1.72vw',
        lineHeight: '3.28vw',
        sub_lineHeight: '2.25vw'
    },
    {
        size: 's',
        title: '3.43vw',
        subtitle: '1.84vw',
        lineHeight: '3.80vw',
        sub_lineHeight: '2.43vw'
    },
    {
        size: 'm',
        title: '4.17vw',
        subtitle: '2.08vw',
        lineHeight: '4.66vw',
        sub_lineHeight: '2.75vw'
    },
    {
        size: 'l',
        title: '5.15vw',
        subtitle: '2.21vw',
        lineHeight: '5.76vw',
        sub_lineHeight: '2.94vw'
    }
]
export default size;

export const numberSize = [
    {
        size: 'xs',
        number: '3rem',
        about: '0.875rem',
        lineHeight: '3.6rem',
        about_lineHeight: '1.05rem'
    },
    {
        size: 's',
        number: '3.75rem',
        about: '1rem',
        lineHeight: '4.5rem',
        about_lineHeight: '1.2rem'
    },
    {
        size: 'm',
        number: '5rem',
        about: '1.25rem',
        lineHeight: '6rem',
        about_lineHeight: '1.5rem'
    },
    {
        size: 'l',
        number: '6.25rem',
        about: '1.5rem',
        lineHeight: '7.56rem',
        about_lineHeight: '1.82rem'
    }
]