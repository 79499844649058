<script>
import { usePlaylistStore } from "../store/PlaylistStore";
import http from "../networkUtils/networkUtil";
import DeleteScreenModal from "./DeleteScreenModal.vue";

export default {
    name: "PlayerInfo",
    props: ['togglePlayerInfo','toggleCachedUrls','cachedUrls'],
    components: {
        DeleteScreenModal,
    },
    mounted: function(){
        this.getScreenDetails();
        this.getPlaylistName();
        this.handleDimensions();
        this.handleAspectRatio();
        this.handleOrientation();
        this.handleRotation();
        this.handleAgent();
        setInterval(() => {
            this.handleMemoryInfo();
        }, 1000);
        this.getCachedUrls();
    },
    setup: function () {
        var playlistStore = usePlaylistStore();

        return {
            playlistStore: playlistStore,
            DeleteScreenModal,
        };
    },
    data: function(){
        return{
            dimensions: '',
            aspectratio: '',
            orientation: '',
            memory: '0 MiB | 0 MiB | 0 GiB',
            rotation: '',
            agent: '',
            screen: '',
            showDeleteModal: false,
            screenName: '',
            playlistName: '',
            moreDetails: false
        }
    },
    methods: {
        handleDimensions: function(){
            let screenWidth = window.innerWidth;
            let screenHeight = window.innerHeight;
            this.dimensions = screenWidth +'x'+ screenHeight
        },
        handleAspectRatio: function(){
            let screenWidth = window.innerWidth;
            let screenHeight = window.innerHeight;
            let aspectRatio = (screenWidth / screenHeight).toFixed(2);
            if (aspectRatio > 1.77) {
                this.aspectratio = 'Widescreen'
            } else {
                this.aspectratio = 'Standard'
            }
        },
        handleOrientation: function(){
            let screenOrientation = window.screen.orientation.type; 
            this.orientation = screenOrientation; 
        },
        handleRotation: function(){
            let screenRotation = screen.orientation.angle;  
            if(screenRotation === 0){
                this.rotation = 'Normal (0deg)';
            } else if(screenRotation === 90){
                this.rotation = 'Clockwise (90deg)';
            } else if(screenRotation === 180){
                this.rotation = 'Upside Down (180deg)';
            } else if(screenRotation === 270){
                this.rotation = 'Counter Clockwise (270deg)';
            }
        },
        handleAgent: function() {
            let userAgent = navigator.userAgent;
            this.agent = userAgent;
        },
        handleMemoryInfo: function() {
            let memoryInfo = "Not available"; 
            if (performance.memory) {
                let usedMemory = (performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2);  
                let totalMemory = (performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(2);  
                let memoryLimit = (performance.memory.jsHeapSizeLimit / 1024 / 1024 / 1024).toFixed(2);  
                memoryInfo = `${usedMemory} MiB | ${totalMemory} MiB | ${memoryLimit} GiB`;
                this.memory = memoryInfo;
            } else {
                memoryInfo = `0 MiB | 0 MiB | 0 GiB`;
                this.memory = memoryInfo;
            }
        },
        getCachedUrls: async function() {
            if ('caches' in window) {
                const cacheNames = await caches.keys(); 
                for (const cacheName of cacheNames) {
                    const cache = await caches.open(cacheName);
                    const cachedRequests = await cache.keys(); 
                    cachedRequests.forEach((request) => {
                        if(request.url.startsWith('https://petti-tv.s3.ap-south-1.amazonaws.com') && !this.playlistStore.cachedUrls.includes(request.url)){
                            this.playlistStore.cachedUrls.push(request.url)
                        }
                    }); 
                }
            } else {
                console.log("Caches are not supported in this browser.");
            }
        },
        removeCatchedUrls: function(){
            if ('caches' in window) {
                caches.keys().then((cacheNames) => {
                    cacheNames.forEach((cacheName) => {
                        caches.delete(cacheName);
                    });
                });
                this.playlistStore.cachedUrls.splice(0, this.playlistStore.cachedUrls.length);
            }
        },
        openDeleteModal: function() {
            let uuid = localStorage.getItem('CodeUUID');
            http.get(`/tv/screen/fetch/${uuid}`).then((data)=>{
                this.screen = data.data.screen;
                this.showDeleteModal = true;
            }).catch((err)=>{
                console.log(err)
                this.showDeleteModal = true;
            })
        },
        deleteScreen: function (){
            this.showDeleteModal = false;
        },
        forceDeleteScreen: function (){
            this.showDeleteModal = false;
            localStorage.removeItem("CodeUUID");
            localStorage.removeItem("Code");
            localStorage.setItem("connected", "false");
            location.reload();
        },
        getScreenDetails: function(){
            let uuid = localStorage.getItem('CodeUUID');
            http.get(`/tv/screen/fetch/${uuid}`).then((data)=>{
                this.screen = data.data.screen;
                this.screenName = this.screen.name;
            }).catch((err)=>{
                console.log(err)
            })
        },
        getPlaylistName: function() {
            var playlist = this.playlistStore.playlist.slides; 
            if (playlist && playlist.length > 0) {
                var playlistID = playlist[0].playlist_id;
                http.get(`/playlist/getname/${playlistID}`).then((data)=>{
                    this.playlistName = data.data;
                })
            } else {
                console.log('Playlist is empty or not valid');
            }
        },
        handleMoreDetails: function() {
            this.moreDetails = ! this.moreDetails;
        }
    }
} 
</script>
<template>
    <div style="display: flex; width: 100%">
        <div v-if="!moreDetails" class="control-info" style="gap:24px;">
            <div style="display:flex; flex-direction:column; gap:12px">
                <div style="display: flex; justify-content: space-between;">
                    <p class="screen-info" style="color: #0E121B;">Screen Info</p>
                    <img @click.stop="togglePlayerInfo()" style="cursor: pointer;" src="/img/x-close.svg" alt="">
                </div>
                <hr style="background-color: rgba(0, 0, 0, 0.1); border: none; height: 0.5px;">
                <div style="display:flex; flex-direction:column; gap:24px">
                    <div style="display:flex; gap:24px">
                        <div style="flex:1; gap:4px">
                            <p class="screen-info-title" style="color: #525866; font-weight: 400;">Screen name</p>
                            <p class="screen-info-title" style="color: #0E121B; font-weight: 500; text-transform: capitalize">{{ screenName === '' ? 'Untitled' : screenName }}</p>
                        </div>
                        <div style="flex:1; gap:4px">
                            <p class="screen-info-title" style="color: #525866; font-weight: 400;">Playlist name</p>
                            <p class="screen-info-title" style="color: #0E121B; font-weight: 500;">{{ playlistName ? playlistName : 'Untitled' }}</p>
                        </div>
                    </div>
                    <div >
                        <p class="screen-info-title" style="color: #525866; font-weight: 400;">Memory</p>
                        <p class="screen-info-title" style="color: #0E121B; font-weight: 500;">{{memory}}</p>
                    </div>
                </div>
            </div>
            <hr style="background-color: rgba(0, 0, 0, 0.1); border: none; height: 0.5px;">
            <div style="display: flex; flex-direction:column; gap:20px;">
                <div style="display: flex; gap:20px;">
                    <button class="screen-info screen-info-btns" style="flex:1; color: #525866; cursor: pointer;" @click.stop="toggleCachedUrls">List cached urls</button>
                    <button class="screen-info screen-info-btns" style="flex:1; color: #525866; cursor: pointer;" @click.stop="removeCatchedUrls">Clear cache</button>
                </div>
                <div style="display: flex;">
                    <button class="screen-info screen-info-btns" style="flex:1; color: #525866; cursor: pointer;" @click.stop="handleMoreDetails">More details</button>
                </div>
                <div style="display: flex;">
                    <button class="screen-info screen-info-btns" style="flex:1; color: #FB3748; cursor: pointer;" @click.stop="openDeleteModal">Disconnect this screen</button>
                </div>
            </div>
        </div>
        <div v-else class="control-info" style="gap:12px">
            <div style="display: flex; justify-content: space-between">
                <div>
                    <p class="screen-info" style="color: #0E121B;">More Details</p>
                </div>
                <div>
                    <img @click.stop="togglePlayerInfo()" style="cursor: pointer;" src="/img/x-close.svg" alt="">
                </div>
            </div>
            <hr style="background-color: rgba(0, 0, 0, 0.1); border: none; height: 0.5px;">
            <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 24px">
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Screen name</p>
                    <p class="value">{{ screenName === '' ? 'Untitled' : screenName }}</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Screen rotation:</p>
                    <p class="value">{{ rotation }}</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Player versions:</p>
                    <p class="value">0.0.0.0</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Screen dimensions:</p>
                    <p class="value">{{ dimensions }}</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Screen aspect ratio:</p>
                    <p class="value">{{aspectratio}}</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Screen orientation:</p>
                    <p class="value">{{orientation}}</p>
                </div>
                
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Agent:</p>
                    <p class="value">{{agent}}</p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 4px;">
                    <p class="title">Memory:</p>
                    <p class="value">{{memory}}</p>
                </div>
            </div>
            
        </div>
        <DeleteScreenModal
            v-if="showDeleteModal"
            @close="showDeleteModal = false"
            @delete="deleteScreen"
            @forceDelete="forceDeleteScreen"
            :currentScreen="screen"
        />
    </div>
</template>
<style>
 .screen-info-main{
    background-color: #171717; 
    color: white; 
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 200px
 }
 .screen-info-top{
    display: flex;
    justify-content: space-between;
    align-items: start;
 }
 .screen-info-main img{
    width: 20px;
    height: 20px;
 }
 .screen-info-center{
    display: flex;
    flex-direction: column;
    gap: 12px;
 }
 .title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #525866;
 }
 .value{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #0E121B;
 }
 .cache-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #03d549af;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    color: #FFFFFF;
    outline: none;
    border: none;
 }
 .disconnect-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 6px;
    background: #dbdbdb;
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.006em;
    color: #333;
    outline: none;
    border: none;
 }
 .control-info{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 385px;
    height: 405px;
    background: #FFFFFF;
    border: 1px solid #F5F7FA;
    box-shadow: 0px 78px 47px rgba(0, 0, 0, 0.01), 0px 35px 35px rgba(0, 0, 0, 0.02), 0px 9px 19px rgba(0, 0, 0, 0.03);
    border-radius: 11.7447px;
 }
 .screen-info {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    font-feature-settings: 'cv09' on, 'ss11' on, 'calt' off, 'liga' off;
 }
 .screen-info-title {
    font-family: 'Inter';
    font-size: 12px;
    line-height: 16px;
 }
 .screen-info-btns{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 4px;
    background: #FFFFFF;
    border: 1px solid #E1E4EA;
    box-shadow: 0px 1px 2px rgba(10, 13, 20, 0.03);
    border-radius: 10px;
 }
</style>